import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    const handleRevokeConsent = () => {
        // Trigger consent flow logic or clear consent preferences
        alert("Consent revoked. The consent message will be displayed again.");
        // Example: clear localStorage or cookies storing consent data
        // localStorage.removeItem("userConsent");
        // Display the consent prompt again if integrated with a consent management library
    };

    return (
        <div className="PrivacyPolicy">
            <header className="PrivacyPolicy-header">
                <h1>Privacy Policy</h1>
                <p>
                    <strong>Effective Date:</strong> 25 December, 2024
                </p>
                <p>
                    This Privacy Policy explains how we collect, use, and protect your personal data, ensuring compliance with the General Data Protection Regulation (GDPR) and Google’s EU User Consent Policy for users in the European Economic Area (EEA), the UK, and Switzerland.
                </p>

                <h2>1. Scope and PrivacyPolicylicability</h2>
                <p>
                    This Privacy Policy applies to all data collected through your use of our app and associated services, including data collected via third-party tools such as Google AdMob.
                </p>

                <h2>2. Information We Collect</h2>
                <p>We collect the following types of data:</p>
                <ul>
                    <li>
                        <strong>Personal Data:</strong> Collected only with your explicit consent, including:
                        <ul>
                            <li>Device identifiers</li>
                            <li>IP addresses</li>
                            <li>Advertising IDs</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Non-Personal Data:</strong> Automatically collected information such as:
                        <ul>
                            <li>PrivacyPolicy usage statistics</li>
                            <li>Device and browser type</li>
                            <li>Language preferences</li>
                        </ul>
                    </li>
                </ul>

                <h2>3. Purpose of Data Collection</h2>
                <p>
                    The data we collect is used for:
                </p>
                <ul>
                    <li>Personalized and non-personalized advertising via Google AdMob.</li>
                    <li>Analytics to improve app performance and user experience.</li>
                    <li>Compliance with legal and regulatory obligations.</li>
                </ul>

                <h2>4. Consent Management</h2>
                <p>
                    To comply with the EU User Consent Policy, we:
                </p>
                <ul>
                    <li>Request and store users' legally valid consent before collecting personal data.</li>
                    <li>Provide clear, accessible instructions for managing consent preferences.</li>
                    <li>
                        Clearly disclose all third parties (e.g., Google AdMob) that may collect, receive, or process your data.
                    </li>
                </ul>

                <h2>5. Data Sharing and Third Parties</h2>
                <p>
                    We share your data with trusted third parties only as necessary:
                </p>
                <ul>
                    <li>
                        <strong>Google AdMob:</strong> Provides personalized and non-personalized ads. Learn more:{" "}
                        <a
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Google Privacy Policy
                        </a>
                    </li>
                    <li>
                        <strong>Service Providers:</strong> Partners assisting with app analytics, hosting, and maintenance. These parties are contractually bound to protect your data.
                    </li>
                </ul>

                <h2>6. Data Storage and Retention</h2>
                <p>
                    Data is stored securely and retained only as long as necessary to fulfill the purposes outlined in this policy. When no longer needed, data is deleted or anonymized.
                </p>

                <h2>7. Your GDPR Rights</h2>
                <p>
                    Under GDPR, you have the right to:
                </p>
                <ul>
                    <li>Access the personal data we hold about you.</li>
                    <li>Request corrections or deletions of your data.</li>
                    <li>Restrict or object to the processing of your data.</li>
                    <li>
                        Withdraw consent for data processing (e.g., personalized ads) at any time via your app settings or by contacting us.
                    </li>
                    <li>
                        File a complaint with your local Data Protection Authority if your rights are violated.
                    </li>
                </ul>

                <h2>8. Security Measures</h2>
                <p>
                    We implement technical and organizational measures to safeguard your data against unauthorized access, disclosure, or destruction.
                </p>

                <h2>9. Updates to This Privacy Policy</h2>
                <p>
                    This Privacy Policy may be updated periodically. Changes will be reflected on this page with a revised effective date. We encourage you to review this policy regularly.
                </p>

                <h2>10. Contact Information</h2>
                <p>
                    For questions or concerns about this Privacy Policy, please contact us:
                </p>
                <ul>
                    <li>
                        <strong>Email:</strong> inbox@appcave.nl
                    </li>
                    <li>
                        <strong>Address:</strong> Zwale Kaluwstraat 21, Alblasserdam, Nederland
                    </li>
                </ul>

                <h2>11. Privacy Policy URL</h2>
                <p>
                    For detailed information, visit our dedicated privacy policy page:{" "}
                    <a
                        href="https://web-cave-pi.vercel.app/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy Page
                    </a>
                </p>

                <h2>12. Consent Revocation</h2>
                <p>
                    If you wish to revoke your consent for personalized advertisements or data collection, click the link below. Doing so will reset your consent preferences, and the consent message will be shown again.
                </p>
                <a
                    href="#"
                    onClick={handleRevokeConsent}
                    style={{ color: "lightblue", textDecoration: "underline", cursor: "pointer" }}
                >
                    Revoke Consent
                </a>
            </header>
        </div>
    );
}

export default PrivacyPolicy;
