import './App.css';
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import {Route, Routes} from "react-router-dom";
import FAQ from "./components/FAQ";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Home from "./components/Home";
import Contact from "./components/Contact";
// import CRMApp from "./components/CRMApp";

function App() {

  return (
      <div className="App">
        <AppHeader />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/contact" element={<Contact />} />
            {/*<Route path="/crm" element={<CRMApp />} />*/}

            {/*<Route path="/offerte" element={<RequestQuote />} />*/}

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <AppFooter />
      </div>
  );
}

export default App;
