import React, { useState } from "react";
import './Contact.css';

function Contact() {
    const [message, setMessage] = useState("");

    // Handle input change
    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (message.trim() === "") {
            alert("Please enter a message.");
            return;
        }
        const phoneNumber = "+31647451470";
        const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank"); // Opens WhatsApp chat
    };

    return (
        <div className="Contact">
            <h1>CONTACT</h1>
            <legend>Uw bericht wordt verzonden via WhatsApp</legend>
            <legend>Wij hanteren een snelle reactie tijd zonder het gebruik van chat robots</legend>
            <div className="contact-container">
                <form className="contact-form" onSubmit={handleSubmit}>
                <textarea
                    className="contact-message"
                    value={message}
                    onChange={handleMessageChange}
                    placeholder="Schrijf hier je bericht..."
                    rows="6"
                    required
                />
                    <button type="submit" className="contact-submit">
                        Verstuur Bericht
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Contact;
