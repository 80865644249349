import React, {useState} from 'react';
import '../App.css';
import {GrContact} from "react-icons/gr";
import {useNavigate} from "react-router-dom";

function AppFooter() {
    const [isHeaderVisible, setHeaderVisible] = useState(true);
    const [isTransitioning, setIsTransitioning] = useState(false); // Track transition state
    const [isLoading, setIsLoading] = useState(false);
    // const [showConnectComponent, setShowConnectComponent] = useState(false);
    const navigate = useNavigate();

    const handleTransitionEnd = () => {
        setIsTransitioning(false); // Allow toggling again once transition is done
    };
    const toggleHeaderVisibility = () => {
        if (!isTransitioning) {
            setIsTransitioning(true); // Block toggling during transition
            setHeaderVisible(!isHeaderVisible); // Toggle visibility
        }
    };
    const handleRenderContact = () => {
        navigate("/contact"); // Redirects to /faq
        toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    };
    return (
        <footer className="App-footer">
            {/*/!*<PrivacyPolicy/>*!/*/}
            {/*<a*/}
            {/*    className="App-link"*/}
            {/*    href="/privacy-policy"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*>*/}
            {/*    Privacy Policy*/}
            {/*</a>*/}
            <button className="header-container-value" onClick={handleRenderContact}>
                <GrContact style={{marginRight: '8px'}}/> CONTACT
            </button>
        </footer>
    );
}

export default AppFooter;
