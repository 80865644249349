import React, {useState} from 'react';
import {BsMenuApp, BsMenuAppFill} from "react-icons/bs";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import { FiHelpCircle, FiHome } from "react-icons/fi";
import LoadingSpinner from "./LoadingSpinner";
import { useNavigate } from "react-router-dom";

function AppHeader( ) {
    const [isHeaderVisible, setHeaderVisible] = useState(true);
    const [isTransitioning, setIsTransitioning] = useState(false); // Track transition state
    const [isLoading, setIsLoading] = useState(false);
    // const [showConnectComponent, setShowConnectComponent] = useState(false);
    const navigate = useNavigate();

    const handleTransitionEnd = () => {
        setIsTransitioning(false); // Allow toggling again once transition is done
    };
    const toggleHeaderVisibility = () => {
        if (!isTransitioning) {
            setIsTransitioning(true); // Block toggling during transition
            setHeaderVisible(!isHeaderVisible); // Toggle visibility
        }
    };

    // Handle rendering different components based on user interaction
    const handleRenderFAQ = () => {
        navigate("/faq"); // Redirects to /faq
        toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    };
    // Handle rendering different components based on user interaction
    const handleRenderHome = () => {
        navigate("/"); // Redirects to /faq
        toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    };
    const handleRenderCRMApp = () => {
        navigate("/crm"); // Redirects to /faq
        toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    };
    // const handleRenderContact = () => {
    //     navigate("/contact"); // Redirects to /faq
    //     toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    // };
    // const handleRenderRequestQuote = () => {
    //     navigate("/offerte"); // Redirects to /faq
    //     toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    // };

    // const handleRenderReferralProgram = () => {
    //     navigate("/referral-program"); // Redirects to /faq
    //     toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    // };
    //
    // const handleRenderConnect = () => {
    //     setIsLoading(true); // Show the loading spinner first
    //     setShowConnectComponent(false); // Hide the Connect component initially
    //
    //     // Simulate a short loading delay before setting the component
    //     setTimeout(() => {
    //         setIsLoading(false); // Stop the loading spinner
    //         navigate("/connect"); // Redirects to /faq
    //         toggleHeaderVisibility(); // Toggle the header visibility after setting the selected component
    //     }, 1000); // Adjust the time for how long you want the spinner to show
    // };

    return (
        <>
            <button
                className="toggle-header-btn"
                onClick={toggleHeaderVisibility}
                disabled={isTransitioning}
            >
                {isHeaderVisible ? (
                    <>
                        <BsMenuApp/> <FaChevronUp style={{fontSize: '34px', marginRight: '8px'}}/>
                    </>

                ) : (
                    <>
                        <BsMenuAppFill/> <FaChevronDown style={{fontSize: '34px', marginRight: '8px'}}/>
                    </>
                )}
            </button>
            <div
                className={`App-header ${!isHeaderVisible ? 'hidden' : ''}`}
                onTransitionEnd={handleTransitionEnd} // Trigger function when transition ends
            >
                {/*<div className="logo-container">*/}
                {/*    /!*<img src={firstsketch} className="App-logo-header" alt="logo" />*!/*/}
                {/*    <h1 className="typing-overlay">*/}
                {/*        <TypingEffect text="DeveloperHuren.nl" />*/}
                {/*    </h1>*/}
                {/*</div>*/}

                <div className="outer-header-container">
                    <div className="header-container">
                        <button className="header-container-value" onClick={handleRenderHome}>
                            <FiHome style={{marginRight: '8px'}}/> HOME
                        </button>
                        <button className="header-container-value" onClick={handleRenderFAQ}>
                            <FiHelpCircle style={{marginRight: '8px'}}/> FAQ
                        </button>
                        {/*<button className="header-container-value" onClick={handleRenderCRMApp}>*/}
                        {/*    <FiHelpCircle style={{marginRight: '8px'}}/> CRM*/}
                        {/*</button>*/}
                        {/*<button className="header-container-value" onClick={handleRenderContact}>*/}
                        {/*    <GrContact style={{marginRight: '8px'}}/> CONTACT*/}
                        {/*</button>*/}
                        {/*<button className="header-container-value" onClick={handleRenderRequestQuote}>*/}
                        {/*    <MdOutlineRequestQuote style={{marginRight: '8px', fontSize: '1.3rem'}}/> OFFERTE*/}
                        {/*</button>*/}

                        {/*<button className="header-container-value" onClick={handleRenderReferralProgram}>*/}
                        {/*    <FiGift style={{marginRight: '8px'}}/> REFERRAL PROGRAM*/}
                        {/*</button>*/}
                        {/*<button className="header-container-value" onClick={handleRenderConnect}>*/}
                        {/*    <FiLink style={{marginRight: '8px'}}/> CONNECT*/}
                        {/*</button>*/}
                        {/*<button className="header-container-value" onClick={''}>*/}
                        {/*    <FiPhone style={{marginRight: '8px'}}/> <span style={{textTransform: 'lowercase'}}>i</span>OS*/}
                        {/*    / ANDROID*/}
                        {/*</button>*/}
                    </div>
                    {/*<AppStoreDownload className="header-container-value-i"/>*/}
                </div>
                <div className="white-space-bottom"></div>
            </div>
            {/* Display the loading spinner while loading */}
            {isLoading && <LoadingSpinner />}
        </>
    );
}

export default AppHeader;
