import React from 'react';
import './Home.css';
import FLYER from '../assets/FLYER.png';
import FlyerERP from '../assets/FlyerERP.png';

function Home() {
    return (
        <div className="Home">
            <div>
                {/*<h1>Welkom bij DeveloperHuren.nl</h1>*/}
                {/*<p>Website, Mobiele Applicatie of Software bij jou op locatie of op afstand </p>*/}
                {/*<p>Wij schrijven code en brengen infrastructuur aan vanaf de basis voor maximale flexibiliteit, snelheid en kwaliteit. </p>*/}
                {/*<p>U kunt ons inhuren voor €500,- per dag. Ben je benieuwd naar een prijs-specificatie op maat?</p>*/}
                <img src={FLYER} alt="Wij bouwen code en infrastructur vanaf de basis, met maximale flexibiliteit, snelheid, kwaliteit en schaalbaarheid.
• WEBSITES
r Overheidsinstellingen & woningcorporaties
‹ Bedrijfswebsites & E-commerce (geen templates!)
• Banenplatforms & monitoring panels
• Portfolio's & high-converting landing pages
• Mobiele Applicaties r Bedrijfsapps & financiële tools
‹ Loyalty-programma's & beloningsapps r Navigatie & geolocatie-oplossingen v E-commerce, social media & chat-apps
API & SOFTWARE
Maatwerk tools voor jouw website of app r Automatisering van tijdrovende processen r Real-time data synchronisatie tussen platforms
In te huren voor €500,- per dag.
Wij werken op locatie of op afstand - net wat jij nodig hebt.
Ben je benieuwd naar een prijs op maat?
Neem contact op!"
                     className="flyer"/>
                <img src={FlyerERP}
                     alt="ERP-Systeem op Maat
Wij bouwen code en infrastructur vanaf de basis, met maximale flexibiliteit, snelheid, kwaliteit en schaalbaarheid.
• Wat bieden wij?
• Maatwerkoplossingen
Geen standardpakketten, maar een system dat perfect past bij jouw bedrijf
• Modulaire opbouw
Kies alleen de functies die je nodig hebt en breid it wanneer nodig.
• Automatisering
Verminder handmatig werk door slimme workflows en Al-integratie.
• Realtime inzicht
Dashboards en rapportages voor betere beslissingen.
Cloud-gebaseerd of On-premise
Flexibiliteit in hosting en beheer.
• Koppelingen met bestaande software
Integratie met boekhouding, CRM, HRM en meer.
Modules op maat
Finance & Accounting
Automatische facturatie, kostenbeheer en financiële dashboards.
• Supply Chain & Logistiek
Voorraadbeheer, inkoopoptimalisatie en track & trace.
₫ HR & Payroll
Salarisadministratie, verlofregistratie en medewerkersbeheer.
• E-commerce & Sales
Orderbeheer, klantenservice en marketing automation.
$ Productie & Planning
Efficiënte productieworkflows en voorraadprognoses.
• Waarom kiezen voor ons?
• Volledig schaalbaar -
• Veilig en betrouwbaar -
• Gebruiksvriendelijke interface -
• 24/7 Ondersteuning -
Groeit mee met jouw onderneming.
Databeveiliging op het hoogste niveau.
Geen technische kennis nodig.
Wij staan altija voor je klaar.
In te huren voor €500,- per dag.
Wij werken op locatie of op afstand - net wat jij nodig hebt.
Ben je benieuwd naar een prijs op maat?
Neem contact"
                     className="flyer"/>

                {/*<FAQ />*/}
                {/*<Contact />*/}
            </div>
            {/*<div className="area">*/}
            {/*    <h2>📞 Neem Contact Op</h2>*/}
            {/*    <p>*/}
            {/*        Via de <a href="/contact" className="App-link">contactpagina</a> kun je direct met ons in contact*/}
            {/*        komen om je wensen en vragen te bespreken.*/}
            {/*    </p>*/}
            {/*</div>*/}
            {/*<div className="area">*/}
            {/*<h2>🚀 Wat Wij Bieden:</h2>*/}
            {/*<ul className="features">*/}
            {/*    <li>✅ <strong>Websites, Apps & Software</strong> op maat</li>*/}
            {/*    /!*<li>✅ <strong>React, React-Native & Spring Boot</strong> voor snelheid & schaalbaarheid</li>*!/*/}
            {/*    <li>✅ <strong>Ontwikkeling</strong> zonder builders zoals wordpress, firebase etc</li>*/}
            {/*    <li>✅ <strong>Onderhoud & Optimalisatie</strong> mogelijk vanaf €50,- per uur</li>*/}
            {/*</ul>*/}

            {/*<h2>💡 Waarom Kiezen Voor Ons?</h2>*/}
            {/*<ul className="features">*/}
            {/*    <li>🔥 <strong>Snel & Efficiënt:</strong> Geen onnodige vertragingen, direct resultaat.</li>*/}
            {/*    <li>🔥 <strong>Transparante prijzen:</strong> Geen verborgen kosten.</li>*/}
            {/*    <li>🔥 <strong>Flexibele samenwerking:</strong> Op afstand of op locatie.</li>*/}
            {/*    <li>🔥 <strong>Ervaren developers:</strong> Wij denken met je mee en bieden de beste oplossingen.</li>*/}
            {/*</ul>*/}
            {/*</div>*/}
        </div>
    );
}

export default Home;
