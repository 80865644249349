import React from "react";
import './LoadingSpinner.css'; // External CSS file

const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>
    );
};

export default LoadingSpinner;
