import React, { useState } from 'react';
import { FaMinus, FaPlus } from "react-icons/fa";
import './FAQ.css';
import {ImPriceTag} from "react-icons/im";
import {GoWorkflow} from "react-icons/go";
import {BiSupport} from "react-icons/bi";
import {LiaMittenSolid} from "react-icons/lia";

function FAQ() {
    // State to track expanded sections
    const [expandedSections, setExpandedSections] = useState([]);
    // State to track expanded questions
    const [expandedQuestions, setExpandedQuestions] = useState([]);

    // Toggle function for section expansion
    const toggleSection = (index) => {
        setExpandedSections((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    // Toggle function for question expansion
    const toggleQuestion = (index) => {
        setExpandedQuestions((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    // FAQ sections with questions and answers
    const faqSections = [
        {
            title: (
                <>
                    <ImPriceTag style={{ marginRight: '8px' }} /> PRIJS
                </>
            ),
            questions: [
                {
                    question: "Wat kost het om een developer in te huren?",
                    answer: "Wij hanteren een tarief van €500,- per dag voor ontwikkeling. Onderhoud wordt aangeboden voor €50,- per uur."
                },
                {
                    question: "Zijn er extra kosten verbonden aan het inhuren?",
                    answer: "Nee, de prijs is transparant. Indien gewenst kunnen wij langskomen op locatie, waarbij reiskosten kunnen worden besproken."
                }
            ]
        },
        {
            title: (
                <>
                    <GoWorkflow style={{ marginRight: '8px' }} /> WERKWIJZE
                </>
            ),
            questions: [
                {
                    question: "Hoe snel kunnen jullie een project opleveren?",
                    answer: "De oplevering hangt af van de complexiteit van het project. Voor een inschatting kun je vrijblijvend een offerte aanvragen."
                },
                {
                    question: "Kunnen jullie bestaande projecten overnemen?",
                    answer: "Ja, wij kunnen bestaande projecten optimaliseren, uitbreiden en onderhouden. Neem contact op voor een analyse van jouw project."
                }
            ]
        },
        {
            title: (
                <>
                    <BiSupport style={{ marginRight: '8px' }} /> SUPPORT
                </>
            ),
            questions: [
                {
                    question: "Krijg ik support na oplevering?",
                    answer: "Ja, wij reageren snel op WhatsApp berichten zonder het gebruik van chatbots."
                },
            ]
        },
        {
            title: (
                <>
                    <LiaMittenSolid style={{ marginRight: '8px' }} /> LIMITATIES
                </>
            ),
            questions: [
                {
                    question: "Zitten er limitaties vast aan waar jullie toe in staat zijn? ",
                    answer: "Nee, wij rekenen €500,- per dag."
                },
                {
                    question: "Zijn er extra kosten verbonden aan het inhuren?",
                    answer: "Nee, de prijs is transparant. Indien gewenst kunnen wij langskomen op locatie, waarbij reiskosten kunnen worden besproken."
                }
            ]
        },
        {
            title: (
                <>
                    <BiSupport style={{ marginRight: '8px' }} /> SPOED
                </>
            ),
            questions: [
                {
                    question: "Kan ik met spoed geholpen worden? ",
                    answer: "Ja, wij reageren snel op WhatsApp berichten zonder het gebruik van chatbots."
                },
            ]
        },

    ];

    return (
        <div className="FAQ">
            <h1>FAQ</h1>
            <div className="faq-content">
                {faqSections.map((section, sectionIndex) => (
                    <div key={sectionIndex} className="faq-section">
                        {/* Section title with toggle */}
                        <h2 className="faq-title" onClick={() => toggleSection(sectionIndex)}>
                            {section.title}
                            <span className="toggle-icon">
                                {expandedSections.includes(sectionIndex) ? (
                                    <FaMinus style={{marginLeft: '8px'}}/>
                                ) : (
                                    <FaPlus style={{marginLeft: '8px'}}/>
                                )}
                            </span>
                        </h2>

                        {/* Render questions only if section is expanded */}
                        {expandedSections.includes(sectionIndex) &&
                            section.questions.map((item, index) => (
                                <div key={index} className="faq-item">
                                    <div className="question"
                                         onClick={() => toggleQuestion(`${sectionIndex}-${index}`)}>
                                    <span
                                        className={`question-text ${expandedQuestions.includes(`${sectionIndex}-${index}`) ? 'underline-active' : 'underline-inactive'}`}>
                                        {item.question}
                                    </span>
                                        <span className="toggle-icon">
                                        {expandedQuestions.includes(`${sectionIndex}-${index}`) ? <FaMinus/> :
                                            <FaPlus/>}
                                    </span>
                                    </div>
                                    {expandedQuestions.includes(`${sectionIndex}-${index}`) &&
                                        <p className="answer">{item.answer}</p>}
                                </div>
                            ))}
                    </div>
                ))}
            </div>
        </div>

    );

}

export default FAQ;
